<template>
  <div class="search-segment-wrap">
    <div class="search-input-wrap">
      <input
        id="searchInput"
        v-model="searchQuery"
        type="text"
        placeholder="Type to search..."
        @input="debouncedSearch(searchQuery)"
      />
      <button class="refresh-button" :disabled="dataLoading" @click="forceRefresh">
        Refresh Data
      </button>
    </div>

    <div id="container">
      <!-- Segment Results Panel -->
      <div class="seg-panel-wrap-left">
        <div class="panel-header">
          <div class="seg-panel-title">
            Segment Results
            <div class="segment-count">{{ resultsCount }}</div>
          </div>
          <div class="panel-header-right">
            <button class="filter-button" @click="toggleFilterPanel">
              <i class="fa-solid fa-filter"></i>
            </button>
            <button class="action-button" @click="addAllSegments">Add All</button>
          </div>
        </div>

        <!-- Filter Panel -->
        <div v-if="filterPanelVisible" id="filterPanel" class="filter-panel">
          <button class="close-filter" @click="toggleFilterPanel">
            <i class="fa-solid fa-xmark"></i>
          </button>
          <div class="filter-row">
            <div class="filter-column">
              <div class="filter-group">
                <label>Provider</label>
                <k-select
                  id="providerFilter"
                  v-model="currentFilters.providers"
                  multiple="multiple"
                  class="provider-filter"
                  :options="providerOptions"
                >
                </k-select>
              </div>
            </div>
          </div>
          <div class="filter-row">
            <div class="filter-column">
              <div class="filter-group">
                <label>IMPs</label>
                <div class="filter-inputs">
                  <input
                    v-model.number="currentFilters.reachMin"
                    type="number"
                    class="number-input"
                    placeholder="Min"
                  />
                  <input
                    v-model.number="currentFilters.reachMax"
                    type="number"
                    class="number-input"
                    placeholder="Max"
                  />
                </div>
              </div>
            </div>
            <div class="filter-column">
              <div class="filter-group">
                <label>Users</label>
                <div class="filter-inputs">
                  <input
                    v-model.number="currentFilters.usersMin"
                    type="number"
                    class="number-input"
                    placeholder="Min"
                  />
                  <input
                    v-model.number="currentFilters.usersMax"
                    type="number"
                    class="number-input"
                    placeholder="Max"
                  />
                </div>
              </div>
            </div>
            <div class="filter-column">
              <div class="filter-group">
                <label>CPM</label>
                <div class="filter-inputs">
                  <input
                    v-model.number="currentFilters.cpmMin"
                    type="number"
                    class="number-input"
                    placeholder="Min"
                  />
                  <input
                    v-model.number="currentFilters.cpmMax"
                    type="number"
                    class="number-input"
                    placeholder="Max"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="filter-buttons">
            <button class="action-button remove" @click="clearFilters">
              Clear Filters
            </button>
            <button class="action-button" @click="applyFilters">Apply Filters</button>
          </div>
        </div>

        <!-- Segment Table -->
        <div class="segment-table">
          <table>
            <thead class="segment-table-header">
              <tr>
                <th style="width: 40px"></th>
                <th class="col-name" @click="setSortSegments('name')">
                  Name
                  <i :class="getSortIcon('name')" class="sort-icon"></i>
                </th>
                <th class="col-provider" @click="setSortSegments('provider')">
                  Provider
                  <i :class="getSortIcon('provider')" class="sort-icon"></i>
                </th>
                <th class="col-id" @click="setSortSegments('id')">
                  ID
                  <i :class="getSortIcon('id')" class="sort-icon"></i>
                </th>
                <th class="col-imp" @click="setSortSegments('reach')">
                  IMPs
                  <i :class="getSortIcon('reach')" class="sort-icon"></i>
                </th>
                <th class="col-users" @click="setSortSegments('users')">
                  Users
                  <i :class="getSortIcon('users')" class="sort-icon"></i>
                </th>
                <th class="col-cpm" @click="setSortSegments('cpm')">
                  CPM
                  <i :class="getSortIcon('cpm')" class="sort-icon"></i>
                </th>
              </tr>
            </thead>
          </table>
          <RecycleScroller
            :items="filteredSegments"
            :item-size="90"
            key-field="id"
            class="virtual-scroller"
          >
            <template #default="{ item: segment }">
              <div class="segment-row">
                <div>
                  <button @click="toggleSegmentSelection(segment)">
                    {{ selectedSegments.includes(segment.id) ? '-' : '+' }}
                  </button>
                </div>
                <div :title="segment.name">{{ segment.name }}</div>
                <div :title="segment.providerName">{{ segment.providerName }}</div>
                <div>{{ segment.id }}</div>
                <div>{{ segment.reach.impressions }}</div>
                <div>{{ segment.reach.users }}</div>
                <div>{{ segment.price.cpm_usd.toFixed(2) }}</div>
              </div>
            </template>
          </RecycleScroller>
        </div>
      </div>

      <!-- Selected Segments Panel -->
      <div class="seg-panel-wrap-right">
        <div class="panel-header">
          <div class="seg-panel-title">
            Selected Segments
            <div class="segment-count">{{ selectedCount }}</div>
          </div>
          <div class="panel-header-right">
            <button class="action-button remove" @click="removeAllSegments">Remove All</button>
            <button class="action-button" @click="exportSelectedSegments">
              Export <i class="fa-solid fa-arrow-down"></i>
            </button>
          </div>
        </div>
        <div class="segment-table">
          <table>
            <thead class="segment-table-header">
              <tr>
                <th style="width: 40px"></th>
                <th class="col-name" @click="setSortSegments('name', true)">
                  Name
                  <i :class="getSortIcon('name', true)" class="sort-icon"></i>
                </th>
                <th class="col-provider" @click="setSortSegments('provider', true)">
                  Provider
                  <i :class="getSortIcon('provider', true)" class="sort-icon"></i>
                </th>
                <th class="col-id" @click="setSortSegments('id', true)">
                  ID
                  <i :class="getSortIcon('id', true)" class="sort-icon"></i>
                </th>
                <th class="col-imp" @click="setSortSegments('reach', true)">
                  IMPs
                  <i :class="getSortIcon('reach', true)" class="sort-icon"></i>
                </th>
                <th class="col-users" @click="setSortSegments('users', true)">
                  Users
                  <i :class="getSortIcon('users', true)" class="sort-icon"></i>
                </th>
                <th class="col-cpm" @click="setSortSegments('cpm', true)">
                  CPM
                  <i :class="getSortIcon('cpm', true)" class="sort-icon"></i>
                </th>
              </tr>
            </thead>
          </table>
          <RecycleScroller
            :items="getSelectedSegments"
            :item-size="90"
            key-field="id"
            class="virtual-scroller"
          >
            <template #default="{ item: segment }">
              <div class="segment-row">
                <div>
                  <input v-model="selectedSegments" type="checkbox" :value="segment.id" />
                </div>
                <div :title="segment.name">{{ segment.name }}</div>
                <div :title="segment.providerName">{{ segment.providerName }}</div>
                <div>{{ segment.id }}</div>
                <div>{{ segment.reach.impressions }}</div>
                <div>{{ segment.reach.users }}</div>
                <div>{{ segment.price.cpm_usd.toFixed(2) }}</div>
              </div>
            </template>
          </RecycleScroller>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import segmentsApi from '@/api/segments-api';
import { RecycleScroller } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import _ from 'lodash';
import KSelect from '../adready-vue/components/elements/k-select.vue';

export default {
  name: 'SegmentsSearch',
  components: {
    RecycleScroller,
    KSelect,
  },
  data() {
    return {
      debounceTimer: null,
      dataLoading: false,
      providerOptions: [],
      segments: [],
      selectedSegments: [],
      filteredSegments: [],
      currentSort: {
        column: 'reach',
        direction: 'desc',
      },
      filteredTableSort: {
        column: 'reach',
        direction: 'desc',
      },
      selectedTableSort: {
        column: 'reach',
        direction: 'desc',
      },
      currentFilters: {
        providers: [],
        reachMin: null,
        reachMax: null,
        usersMin: null,
        usersMax: null,
        cpmMin: null,
        cpmMax: null,
      },
      filterPanelVisible: false,
      searchQuery: '',
    };
  },
  computed: {
    resultsCount() {
      return `(${this.filteredSegments.length})`;
    },
    selectedCount() {
      return `(${this.selectedSegments.length})`;
    },
    activeFiltersCount() {
      let count = 0;
      if (this.currentFilters.providers.length > 0) count++;
      if (this.currentFilters.reachMin || this.currentFilters.reachMax) count++;
      if (this.currentFilters.usersMin || this.currentFilters.usersMax) count++;
      if (this.currentFilters.cpmMin || this.currentFilters.cpmMax) count++;
      return count;
    },

    getSelectedSegments() {
      const selectedSegments = this.segments.filter((s) => this.selectedSegments.includes(s.id));
      const sortedSelectedSegments = this.sortSegments(
        selectedSegments,
        this.selectedTableSort.column,
        this.selectedTableSort.direction
      );
      return sortedSelectedSegments;
    },
  },
  watch: {
    // Watch for changes in the query and trigger debounced search
    searchQuery(newQuery) {
      this.debouncedSearch(newQuery);
    },
  },
  created() {
    // Create a debounced version of the filter function
    this.debouncedSearch = _.debounce(this.filterSegments, 1000); // 500ms delay
  },
  mounted() {
    this.loadSegments();
  },
  methods: {
    debouncedSearch(query) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.filterSegments(query);
      }, 1000); // 500ms delay
    },
    setSortSegments(column, sortSelected = false) {
      if (sortSelected) {
        // Update sort for selected table
        if (this.selectedTableSort.column === column) {
          // Toggle the sort direction
          this.selectedTableSort.direction =
            this.selectedTableSort.direction === 'asc' ? 'desc' : 'asc';
        } else {
          // Set the new column and default to ascending
          this.selectedTableSort.column = column;
          this.selectedTableSort.direction = 'asc';
        }
      } else {
        // Update sort for filtered table
        if (this.filteredTableSort.column === column) {
          // Toggle the sort direction
          this.filteredTableSort.direction =
            this.filteredTableSort.direction === 'asc' ? 'desc' : 'asc';
        } else {
          // Set the new column and default to ascending
          this.filteredTableSort.column = column;
          this.filteredTableSort.direction = 'asc';
        }

        // Sort the filtered segments
        this.filteredSegments = this.sortSegments(
          this.filteredSegments,
          this.filteredTableSort.column,
          this.filteredTableSort.direction
        );
      }
    },
    getSortIcon(column, selectedTable = false) {
      // Return the appropriate icon class for the column
      let currentSort;
      if (selectedTable) {
        currentSort = this.selectedTableSort;
      } else {
        currentSort = this.filteredTableSort;
      }
      if (currentSort.column === column) {
        return currentSort.direction === 'asc' ? 'fa-solid fa-sort-up' : 'fa-solid fa-sort-down';
      }
      return 'fa-solid fa-sort';
    },
    async loadSegments(forceRefresh = false) {
      try {
        this.dataLoading = true;
        const dbName = 'segmentsDB';
        const storeName = 'segmentsStore';
        const db = await this.openIndexedDB(dbName, storeName);

        if (!forceRefresh) {
          // Check if data is available in IndexedDB
          const segments = await this.getFromIndexedDB(db, storeName, 'segments.json');
          if (segments) {
            this.loadSegmentsFromData(segments);
            this.dataLoading = false;
            return; // Skip the API call
          }
        }

        // If no data in IndexedDB or forceRefresh is true, fetch from API
        const opts = {
          headers: {
            Accept: 'application/json',
          },
          timeout: 60000,
          responseType: 'json',
        };

        const response = await segmentsApi.download(opts);

        if (response.status !== 200) {
          throw new Error(`Failed to download segments. Status: ${response.status}`);
        }

        const segments = response.data.map((segment) => ({
          ...segment,
          reach: JSON.parse(segment.reach),
          price: JSON.parse(segment.price),
        }));

        // Store the fetched data in IndexedDB
        await this.storeInIndexedDB(db, storeName, {
          filename: 'segments.json',
          content: segments,
        });

        // Load data into the UI
        this.loadSegmentsFromData(segments);
        this.dataLoading = false;
      } catch (error) {
        console.error('Error processing segments:', error);
      }
    },
    async getFromIndexedDB(db, storeName, key) {
      return new Promise((resolve, reject) => {
        const transaction = db.transaction([storeName], 'readonly');
        const store = transaction.objectStore(storeName);

        const request = store.get(key);

        request.onsuccess = (event) => {
          const data = event.target.result;
          resolve(data ? data.content : null);
        };

        request.onerror = () => {
          reject(new Error('Failed to retrieve data from IndexedDB'));
        };
      });
    },
    loadSegmentsFromData(segments) {
      this.segments = segments;
      this.filteredSegments = segments;
      this.providerOptions = Array.from(
        new Map(
          segments.map((seg) => [seg?.providerId, { name: seg?.providerName, id: seg?.providerId }])
        ).values()
      );
      this.dataLoading = false;
    },
    loadSegmentsFromIndexedDB(db, storeName) {
      const transaction = db.transaction([storeName], 'readonly');
      const store = transaction.objectStore(storeName);

      const request = store.get('segments.json');

      request.onsuccess = (event) => {
        const data = event.target.result;
        if (data && data.content) {
          this.segments = data.content;
          // need to implement virtual scroll
          this.filteredSegments = data.content; // slicing bcos it has 19k items // initilly all data.
          this.providerOptions = Array.from(
            new Map(
              this.filteredSegments.map((seg) => [
                seg?.providerId, // Use the `providerId` as the key for uniqueness
                { name: seg?.providerName, id: seg?.providerId },
              ])
            ).values()
          );
          // console.log('Segments loaded from IndexedDB:', this.segments);
          // console.log('this.providerOptions', this.providerOptions);
        } else {
          console.warn('No segments found in IndexedDB.');
        }
      };

      request.onerror = () => {
        console.error('Failed to retrieve data from IndexedDB.');
      };
    },
    openIndexedDB(dbName, storeName) {
      return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName, 1);

        request.onupgradeneeded = (event) => {
          const db = event.target.result;

          if (!db.objectStoreNames.contains(storeName)) {
            db.createObjectStore(storeName, { keyPath: 'filename' });
          }
        };

        request.onsuccess = (event) => {
          resolve(event.target.result);
        };

        request.onerror = () => {
          reject(new Error('Failed to open IndexedDB'));
        };
      });
    },

    // Helper function to store data in IndexedDB
    storeInIndexedDB(db, storeName, data) {
      return new Promise((resolve, reject) => {
        const transaction = db.transaction([storeName], 'readwrite');
        const store = transaction.objectStore(storeName);

        const request = store.put(data);

        request.onsuccess = () => resolve();
        request.onerror = () => reject(new Error('Failed to store data in IndexedDB'));
      });
    },
    forceRefresh() {
      this.selectedSegments = [];
      this.filteredSegments = [];
      this.clearFilters();
      this.filterPanelVisible = false;
      this.loadSegments(true);
      this.searchQuery = '';
    },
    toggleFilterPanel() {
      this.filterPanelVisible = !this.filterPanelVisible;
    },
    clearFilters() {
      this.currentFilters = {
        providers: [],
        reachMin: null,
        reachMax: null,
        usersMin: null,
        usersMax: null,
        cpmMin: null,
        cpmMax: null,
      };
    },
    applyFilters() {
      this.filterSegments(this.searchQuery);
    },
    sortSegments(segments, column, direction) {
      return [...segments].sort((a, b) => {
        let valueA;
        let valueB;

        switch (column) {
          case 'provider':
            valueA = a.providerName.toLowerCase();
            valueB = b.providerName.toLowerCase();
            break;
          case 'name':
            valueA = a.name.toLowerCase();
            valueB = b.name.toLowerCase();
            break;
          case 'id':
            valueA = a.id;
            valueB = b.id;
            break;
          case 'reach':
            valueA = a.reach.impressions;
            valueB = b.reach.impressions;
            break;
          case 'users':
            valueA = a.reach.users;
            valueB = b.reach.users;
            break;
          case 'cpm':
            valueA = a.price.cpm_usd;
            valueB = b.price.cpm_usd;
            break;
          default:
            return 0;
        }

        if (direction === 'asc') {
          return valueA > valueB ? 1 : -1;
        }
        return valueA < valueB ? 1 : -1;
      });
    },
    toggleProvider(provider) {
      const index = this.currentFilters.providers.indexOf(provider);
      if (index === -1) {
        this.currentFilters.providers.push(provider);
      } else {
        this.currentFilters.providers.splice(index, 1);
      }
    },
    toggleSegmentSelection(segment) {
      const index = this.selectedSegments.indexOf(segment.id);
      if (index === -1) {
        this.selectedSegments.push(segment.id);
      } else {
        this.selectedSegments.splice(index, 1);
      }
    },
    addAllSegments() {
      // Get IDs of the filtered segments
      const filteredSegmentIds = this.filteredSegments.map((segment) => segment.id);

      // Merge the filtered IDs with already selected IDs
      this.selectedSegments = Array.from(
        new Set([...this.selectedSegments, ...filteredSegmentIds])
      );
    },
    removeAllSegments() {
      this.selectedSegments = [];
    },
    exportSelectedSegments() {
      const selectedData = this.segments.filter((segment) =>
        this.selectedSegments.includes(segment.id)
      );
      const csvContent = `Name,Provider,ID,Impressions,Users,CPM\n${selectedData
        .map((segment) => {
          return `"${segment.name}","${segment.providerName}",${segment.id},${segment.reach.impressions},${segment.reach.users},${segment.price.cpm_usd}`;
        })
        .join('\n')}`;
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'selected_segments.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    // Filter method that applies all filter criteria
    filterSegments(query) {
      let selectedProvidersName = [];
      if (this.currentFilters.providers.length > 0) {
        selectedProvidersName = this.currentFilters.providers.map((prov) => prov.name);
      }
      // console.log('query', query);
      const currentFilteredSegments = this.segments.filter((segment) => {
        // Check if query matches segment name or providerName
        const matchesQuery = query.toLowerCase();
        const matchesName = segment.name && segment.name.toLowerCase().includes(matchesQuery);
        const matchesProvider =
          segment.providerName && segment.providerName.toLowerCase().includes(matchesQuery);

        if (query.length >= 3 && !(matchesName || matchesProvider)) {
          return false;
        }

        // Check for provider filter
        if (
          selectedProvidersName.length > 0 &&
          !selectedProvidersName.includes(segment.providerName)
        ) {
          return false;
        }

        // Check for reach filters
        if (
          this.currentFilters.reachMin &&
          segment.reach.impressions < this.currentFilters.reachMin
        ) {
          return false;
        }
        if (
          this.currentFilters.reachMax &&
          segment.reach.impressions > this.currentFilters.reachMax
        ) {
          return false;
        }

        // Check for users filters
        if (this.currentFilters.usersMin && segment.reach.users < this.currentFilters.usersMin) {
          return false;
        }
        if (this.currentFilters.usersMax && segment.reach.users > this.currentFilters.usersMax) {
          return false;
        }

        // Check for cpm filters
        if (this.currentFilters.cpmMin && segment.price.cpm_usd < this.currentFilters.cpmMin) {
          return false;
        }
        if (this.currentFilters.cpmMax && segment.price.cpm_usd > this.currentFilters.cpmMax) {
          return false;
        }

        return true;
      });
      this.filteredSegments = this.sortSegments(
        currentFilteredSegments,
        this.filteredTableSort.column,
        this.filteredTableSort.direction
      );
    },
    // Method to update the filters dynamically (if needed)
    updateFilters(newFilters) {
      this.currentFilters = { ...this.currentFilters, ...newFilters };
      this.filterSegments(); // Re-filter when filters change
    },
  },
};
</script>
<style lang="scss">
// *,
// *:before,
// *:after {
//   box-sizing: inherit;
// }

.search-segment-wrap {
  width: 100%;
  margin-top: -42px !important;
  margin: 20px auto;
}

.top-title {
  font-size: 23px;
  text-align: center;
  padding: 0px 20px 20px;
  font-weight: 600;
  color: #444450;
}
.search-input-wrap {
  padding: 22px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  background-color: #f1f2f4;
  border-radius: 10px;
  margin-bottom: 30px;
}

input#searchInput {
  width: 100%;
  padding: 14px 20px;
  border-radius: 8px;
  border: 2px solid #c5cbda;
  color: #494b59;
  position: relative;
  font-size: 15px;
}

#loadingIndicator {
  display: none;
  border: 4px solid #e7e7e7;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  position: absolute;
  right: 44px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#container {
  display: flex;
  gap: 20px;
}

.seg-panel-wrap-left {
  flex: 2;
}
.seg-panel-wrap-right {
  flex: 1.7;
}

.segment-table {
  width: 100%;
  background-color: #f3f5f8;
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: hidden;
  max-height: calc(100vh - 350px);
}

.segment-table-body {
  max-height: calc(100vh - 400px);
  overflow-y: auto;
}

.segment-table-header {
  background-color: #e4e6ea;
}
.segment-row {
  display: grid;
  grid-template-columns: 18px 2fr 1fr 0.4fr 0.5fr 0.6fr 0.5fr; /* Column structure */
  gap: 10px; /* Spacing between columns */
  padding: 12px 15px; /* Spacing inside each row */
  border-bottom: 1px solid #e4e6ea; /* Row divider */
  align-items: start; /* Align items to the top */
  font-size: 13px; /* Text size */
}

.segment-row div {
  white-space: nowrap; /* Prevent text wrapping for all columns by default */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
  min-width: 0; /* Prevent unnecessary width issues */
}

.segment-row div:nth-child(2),
.segment-row div:nth-child(3) {
  white-space: normal; /* Allow text to wrap for the first and second columns */
  overflow-wrap: break-word; /* Allow breaking long words */
  word-break: break-word; /* Fallback for older browsers */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
}

.segment-row:hover {
  background-color: #e9eaed;
}

.virtual-scroller {
  height: 400px; /* Adjust height for your UI */
  overflow-y: auto;
}
.panel-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
}

.panel-header-right {
  display: flex;
}

.panel-header-right button {
  margin: 0px 0px 0px 13px;
}

.action-button {
  background-color: #5fb185;
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.refresh-button {
  background-color: #5fb185;
  color: white;
  border: none;
  height: 30px;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

.refresh-button:disabled {
  background-color: #a1d2b6; /* A lighter shade for disabled state */
  color: #e5e5e5; /* Subtle text color for disabled state */
  cursor: not-allowed; /* Indicate that the button is disabled */
  opacity: 0.7; /* Slightly transparent to emphasize the disabled state */
}

.action-button.remove {
  background-color: #d57e7e;
}

.add-button {
  background-color: #5fb185;
  color: white;
  border: none;
  width: 23px;
  height: 23px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 1;
  margin-left: 6px;
  padding: 10px 0px 9px;
}

.remove-button {
  background-color: #d57e7e;
  color: white;
  border: none;
  width: 23px;
  height: 23px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 1;
  padding-bottom: 4px;
  margin-left: 6px;
}

.seg-panel-title {
  font-size: 15px;
  font-weight: bold;
  color: #5e5f6d;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.segment-count {
  color: #666;
  font-size: 13px;
  margin-left: 7px;
  font-weight: 400;
}
.text-right {
  text-align: right;
}

.search-segment-wrap button {
  white-space: nowrap;
}

.search-segment-wrap button:hover {
  filter: brightness(1.1);
}

.search-segment-wrap table {
  width: 100%;
  border-collapse: collapse;
}

.search-segment-wrap thead {
  position: sticky;
  top: 0px;
}

.search-segment-wrap th {
  // background-color: #e4e6ea;
  padding: 12px 5px;
  text-align: left;
  font-weight: bold;
  color: #5e5f6d;
  font-size: 13px;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
}

.search-segment-wrap th:hover {
  background-color: #eceff4;
}

.search-segment-wrap td {
  padding: 12px 5px;
  border-bottom: 1px solid #e4e6ea;
  font-size: 13px;
}

.search-segment-wrap tr:hover {
  background-color: #e9eaed;
}

.search-segment-wrap td.numeric-cell {
  padding-right: 18px;
}

.col-name {
  width: 250px;
  max-width: 190px;
  padding-left: 14px;
}
.col-id,
.col-users {
  width: 40px;
}
.col-imp,
.col-cpm {
  width: 60px;
}
.col-provider {
  width: 100px;
}

.sort-icon {
  margin-left: 5px;
  font-size: 12px;
}

.filter-panel {
  background-color: #f1f2f4;
  padding: 20px;
  margin: 10px 0;
  border-radius: 8px;
  // display: none;
  position: relative;
}

.close-filter {
  position: absolute;
  top: 4px;
  right: 6px;
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.close-filter:hover {
  color: #333;
}

.filter-group {
  margin-bottom: 15px;
}

.filter-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #5e5f6d;
  font-size: 13px;
}

.filter-row {
  display: flex;
  gap: 3px 16px;
  margin-bottom: 5px;
  flex-wrap: wrap;
}

.filter-column {
  flex: 1;
}

.number-input {
  width: calc(50% - 4px);
  padding: 8px;
  border: 1px solid #c5cbda;
  border-radius: 4px;
}

.select2-container {
  width: 100% !important;
}

.filter-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 5px;
}

.numeric-cell {
  text-align: right;
}

.table-header-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filter-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #5e5f6d;
  padding: 4px 8px;
  position: relative;
}

.filter-button:hover {
  color: #3498db;
}

.filter-count {
  position: absolute;
  top: -6px;
  right: -3px;
  background-color: #cd392a;
  color: white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  display: none;
}
.select2-container--default .select2-search--inline .select2-search__field {
  font-size: 0.85em;
  padding-top: 2px;
}
.select2-results__option--selectable {
  font-size: 0.9em;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  font-size: 13px;
}
.select2-container--default .select2-selection--multiple {
  border: 1px solid #c9cde0;
  border-radius: 5px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e6e9f2;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #777c92;
  padding: 1px 4px;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear {
  font-weight: 400;
  font-size: 18px;
}

.hidden {
  display: none;
}

::-webkit-scrollbar {
  width: 7px;
  height: 9px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: rgba(200, 212, 220, 0.6);
  border: 0px none #ffffff;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #5d5c67;
}
::-webkit-scrollbar-thumb:active {
  background: #5d5c67;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
.filter-inputs {
  display: flex;
  gap: 4px;
}

.provider-filter {
  border-radius: 4px;
  background-color: #fff !important;
}

.provider-filter .form-input .form-input-field.v-select .vs__dropdown-toggle {
  background-color: #fff !important;
  border-radius: 4px !important;
}
</style>
