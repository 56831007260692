<template>
  <div class="grid-item">
    <div class="section">
      <div class="filter checkbox-select">
        <div class="filter-ttl"><span>DELIVERY</span></div>
        <div class="checkbox-select__check-wrapp form-group">
          <input id="runNow" v-model="localRunNow" type="checkbox" @change="runNowChanged" />
          <label for="runNow">Run now, show results here</label>
        </div>
        <div
          class="checkbox-select__check-wrapp form-group"
          :class="{ disabled: disableSaveReportTemplate }"
        >
          <input
            id="saveTemplate"
            v-model="localSaveTemplate"
            type="checkbox"
            @change="saveTemplateChanged"
          />
          <label for="saveTemplate">Save as report template</label>
        </div>
        <div v-if="localSaveTemplate">
          <label for="reportName">Name this report</label>
          <div class="input-wrapper">
            <textarea
              id="reportName"
              ref="textarea"
              v-model="localReportName"
              :aria-describedby="isValidReportName ? '' : 'reportNameTooltip'"
              :maxlength="100"
              class="report-name-input"
              rows="1"
              @input="handleInput"
              @keypress="validateInput"
            ></textarea>
            <div class="tooltip-container">
              <font-awesome-icon
                id="reportNameTooltip"
                class="tooltip-icon"
                icon="fas fa-info-circle"
                @mouseenter="showTooltip = true"
                @mouseleave="showTooltip = false"
              />
              <div v-if="showTooltip" class="custom-tooltip">
                Only alphanumeric characters, dashes and underscores are allowed.
              </div>
            </div>
          </div>
          <div class="remaining-characters">{{ remainingCharactersText }}</div>
        </div>
        <button
          class="flp-btn btn-size2"
          style=" z-index:10;padding-left: 17px; margin: 0px; margin-top: 10px; width: 185px !important"
          @click="runOrSaveReport"
        >
          <span>{{ buttonLabel }}</span>
          <span class="flp-btn-icon-size2">
            <font-awesome-icon icon="fal fa-arrow-right" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    runNow: { type: Boolean, required: true },
    saveTemplate: { type: Boolean, required: true },
    reportName: { type: String, required: true },
    buttonLabel: { type: String, required: true },
    isValidReportName: { type: Boolean, required: true },
    disableSaveReportTemplate: { type: Boolean, required: true },
  },
  data() {
    return {
      localRunNow: this.runNow,
      localReportName: this.reportName,
      localSaveTemplate: this.saveTemplate,
      showTooltip: false,
    };
  },
  computed: {
    remainingCharactersText() {
      const remaining = 100 - this.localReportName.length;
      return `${remaining} character${remaining < 2 ? '' : 's'} remaining`;
    },
  },
  watch: {
    reportName(newVal) {
      this.localReportName = newVal;
    },
    saveTemplate(newVal) {
      this.localSaveTemplate = newVal;
    },
    runNow(newVal) {
      this.localRunNow = newVal;
    },
    disableSaveReportTemplate(newVal) {
      this.localSaveTemplate = !newVal;
    },
    localReportName() {
      this.$nextTick(() => {
        this.adjustHeight();
      });
    },
  },
  methods: {
    runNowChanged() {
      this.$emit('run-now-changed', this.localRunNow);
    },
    saveTemplateChanged() {
      this.$emit('save-template-changed', this.localSaveTemplate);
    },
    runOrSaveReport() {
      this.$emit('run-or-save-report');
    },
    handleInput(event) {
      this.localReportName = event.target.value;
      this.$emit('report-name-changed', this.localReportName);
      this.adjustHeight(event);
    },
    adjustHeight(event) {
      const textarea = event ? event.target : this.$refs.textarea;
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    },
    validateInput(event) {
      const regex = /^[a-zA-Z0-9-_ ]*$/;
      if (!regex.test(event.key) && event.key !== 'Backspace') {
        event.preventDefault();
      }
    },
  },
};
</script>

<style scoped>
.grid-item {
  padding: 10px;
  max-width: 210px;
}
.section {
  display: block;
  margin-bottom: 8px;
}
.filter {
  ::v-deep .dropdown .dropdown-menu {
    li svg {
      font-size: inherit !important;
    }
    .intip4 {
      top: 2px !important;
      margin-left: 5px !important;
      &::after {
        bottom: 200% !important;
        width: 198px !important;
        margin-left: -73px !important;
        font-weight: 400 !important;
        text-align: left !important;
        letter-spacing: 0em !important;
      }
    }
  }
  .filter-ttl {
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.1em;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  .filter-group > *:not(:first-child) {
    margin-top: 10px;
  }

  .filter-group {
    margin-bottom: 15px;
    .conv-content {
      position: relative;
      margin-top: 15px;

      .conv-ttl {
        position: absolute;
        top: -80%;
        font-size: 14px;
        color: #707e8a;
      }
    }

    .opt-b1 {
      display: inline-block;
      margin-right: 6px;
    }
    .opt-b2 {
      display: inline-block;
      width: 10rem;
      margin-left: 70px;
      vertical-align: bottom;
      position: absolute;
      margin-top: 5px;
      & .checkbox-btn {
        height: unset;
      }
      ::v-deep .checkbox-btn {
        span {
          position: fixed;
          margin: 0 30px !important;
          font-size: 12px !important;
        }
      }
    }

    .input-conv {
      width: 60px;
    }

    .flp-input {
      padding: 10px;
      background-color: transparent;
      border-radius: 4px;
    }
  }
}

.filter {
  &.checkbox-select {
    position: relative;
    color: var(--primarydark2);
    max-width: 549px;
    width: 100%;
  }

  .checkbox-select__trigger {
    border-radius: 10px;
    background: #fff;
    position: relative;
    z-index: 1;
    height: auto;
    max-height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 10px 8px 15px;
    border: 1px solid #c5d2d8;
    border-radius: 6px;
    transition: all 0.4s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .checkbox-select__check-wrapp {
    margin-bottom: 0px;
    position: relative;

    input[type='checkbox'] {
      display: none;

      & + label {
        position: relative;
        cursor: pointer;
        font-family: 'Manrope', sans-serif;
        font-size: 13px;
        line-height: 22px;
        padding-left: 30px;
        display: inline-block;
        border-radius: 5px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        transition: padding 0.25s ease;

        &:after {
          border: solid 2px var(--primarycolor);
          border-radius: 5px;
          content: '';
          width: 18px;
          height: 18px;
          opacity: 0.7;
          top: 2px;
          left: 0;
          position: absolute;
        }

        &:before {
          width: 14px;
          height: 14px;
          content: '';
          position: absolute;
          top: 0px;
          left: 4px;
          background-color: #fff;
          opacity: 0;
          will-change: transform;
          transform: scale(0.5);
          transition: all 0.2s ease;
        }

        &:hover {
          padding-left: 32px;
        }
      }

      &:disabled {
        color: var(--primarydark);
        opacity: 0.6;
        cursor: auto;
      }

      &:checked {
        & + label {
          &:before {
            margin: 1px 0px 0px 0px;
            opacity: 0.7;
            color: var(--primarycolor);
            font-size: 12px;
            font-family: FontAwesome;
            content: '\f00c';
            transform: scale(1);
          }
        }
      }
    }
  }
}
.checkbox-select__check-wrapp {
  margin-bottom: 10px;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.form-group input[type='checkbox'] {
  margin-right: 10px;
}
.form-group label {
  margin-bottom: 5px;
}
.input-wrapper {
  position: relative;
  display: flex;
  align-items: center; /* Align items vertically */
}

.tooltip-icon {
  margin-left: 5px;
  color: #5a5a5a; /* Neutral gray for icon */
  cursor: pointer;
}

.custom-tooltip {
  position: absolute;
  top: -45px; /* Position above the input */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(245, 245, 250, 1); /* White background */
  color: #333; /* Dark text color */
  padding: 8px 12px;
  border-radius: 3px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
  text-align: center;
}

.custom-tooltip.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease-in-out; /* Smooth fade-in */
}

.flp-btn {
  position: relative;
  position: relative;
  display: inline-block;
  padding: 15px 26px;
  font-size: 12px;
  font-weight: 400;
  color: var(--buttontext);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--primarycolor) !important;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-radius: 3px;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-touch-callout: none;
}
.flp-btn:hover {
  color: #fff !important;
  background-color: #424242 !important;
}
.btn-size2 {
  min-width: 20px;
  font-size: 12px;
  font-weight: 600;
  margin: 0px 7px 0px 10px;
  padding: 7px 12px 8px;
  border-radius: 6px;
  &:hover {
    color: #cad1d6 !important;
    background-color: #424242 !important;
    border: none !important;
  }
}
.flp-btn-icon-size2 {
  position: relative;
  top: 0;
  padding: 3px 4px 7px 12px;
  margin: 0 0 0 13px;
  font-size: 18px;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
}

.report-name-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none; /* Prevent manual resizing */
  overflow: hidden; /* Hide overflow */
  transition: height 0.2s ease; /* Smooth height transition */
}

.remaining-characters {
  margin-top: 5px; /* Space between input and character count */
  font-size: 12px; /* Smaller font for character count */
  color: #5a5a5a; /* Neutral gray for text */
}

.tooltip-container {
  display: flex;
  align-items: center; /* Align tooltip icon and text */
  margin-left: 5px; /* Space between textarea and tooltip */
}
</style>
